import React from 'react';
import styled from 'styled-components';
import { Text, TextVariant } from '@naf/text';
import { breakpoints, spacing } from '@naf/theme';
import { ArticleBlockContentGridCol } from './ArticleBlockContentGridCol';
import { ThreeColumnBlockType } from '../../../../../../../types/ThreeColumnBlockType';
import { MainEntries } from '../../../../SectionBlocks/blocks/ThreeColumnBlock';

interface ThreeColumnBlockSerializerProps {
  node: ThreeColumnBlockType;
}

export const ThreeColumnBlock = ({ node }: ThreeColumnBlockSerializerProps) => (
  <div className="threeColumnBlock">
    {node.title && <Text variant={TextVariant.Header2}>{node.title}</Text>}
    {node.mainEntries && <MainEntries mainEntries={node.mainEntries} />}
  </div>
);

export const ThreeColumnBlockSerializer = (context: 'article' | 'stripped' = 'stripped') => {
  if (context === 'article') {
    return ({ node }: ThreeColumnBlockSerializerProps) => (
      <StyledArticleBlockContentGridCol>
        <ThreeColumnBlock node={node} />
      </StyledArticleBlockContentGridCol>
    );
  }
  return ({ node }: ThreeColumnBlockSerializerProps) => <ThreeColumnBlock node={node} />;
};

const StyledArticleBlockContentGridCol = styled(ArticleBlockContentGridCol)`
  margin: ${spacing.space32} 0;

  @media (max-width: ${breakpoints.m}) {
    margin: ${spacing.space24} 0;
  }
`;

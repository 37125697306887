import styled from 'styled-components';
import { breakpoints, spacing } from '@naf/theme';

export const ButtonContainer = styled.div`
  display: flex;
  text-align: center;
  margin-top: ${spacing.space40};
  margin-bottom: ${spacing.space48};
  gap: ${spacing.space24};

  @media (max-width: ${breakpoints.l}) {
    flex-direction: column;
  }

  @media (max-width: ${breakpoints.s}) {
    margin-bottom: ${spacing.space40};

    button,
    a {
      width: 100%;
    }
  }
`;
